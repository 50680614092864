<template>
  <div class="building-list">
    <div class="back-container">
      <span @click="onBack">&lt;返回</span>
    </div>
    <div v-if="buildings" class="list-container">
      <Card
        class="item-card"
        v-for="data in buildings"
        :key="data.id"
        style="width: 200px"
      >
        <div class="card-info" style="text-align: center; height: 220px">
          <img :src="cover_url(data)" style="width: 90%" />
          <div class="building-info">
            <h3>{{ data.community }}</h3>
            <span>{{ data.location.split("_")[0] }}</span>
            <br />
            <span>{{ data.modify_time }}</span>
          </div>
        </div>
      </Card>
    </div>
    <div v-if="buildings" style="margin: 20px 12px">
      <div style="display: flex; flex-direction: row-reverse">
        <Page
          :total="totalCount"
          :current="currentPage"
          @on-change="changePage"
          :page-size="pageSize"
          show-total
        ></Page>
      </div>
    </div>
    <div v-else>
      <div style="text-align: center; font-size: 18px; margin-top: 40px">
        暂无户型数据
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import empty_house from "@/assets/icon_empty_house.png";

// const testData = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
//   return {
//     id: item,
//     url: "https://jiayiliang-oss.oss-cn-shanghai.aliyuncs.com/house/2085eac0e2d9/pics/2085eac0e2d9.png",
//     title: "title" + item,
//   };
// });
export default {
  data() {
    return {
      buildings: null,
      mobile: "",
      currentPage: 1,
      totalCount: 0,
      pageSize: 30,
    };
  },
  computed: {
    cover_url() {
      return function (data) {
        return data.cover_url || empty_house;
      };
    },
  },
  methods: {
    loadData() {
      api
        .buildingList(this.mobile, this.currentPage, this.pageSize)
        .then((res) => {
          this.$log.debug(res);
          if (res.houses && res.houses.length > 0) {
            this.buildings = res.houses;
            this.totalCount = res.total_count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.$log.debug("currentPage: ", this.currentPage);
      this.loadData();
    },
    onBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.mobile = this.$route.query.id;
    this.$log.debug("mobile: ", this.mobile, this.$route);
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.building-list {
  width: 100%;
  padding: 12px;

  .back-container {
    span {
      font-size: 16px;
    }
  }

  .list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 12px;

    .item-card {
      margin-bottom: 12px;
      margin-right: 8px;
      .building-info {
        text-align: left;
      }
    }

    &::after {
      flex: auto;
      content: "";
    }
  }
}
</style>